<template>
  <div>
    <Header :previousPath="previousPath" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row justify-content-md-center mt-3">
            <div class="col-md-9 col-lg-7 col-xl-6">

              <div class="widget-holder rounded" v-if="!sentMessage">
                <div class="widget-bg">
                  <div class="widget-body">

                    <h2 class="text-center">Contact Us</h2>
                    <h6 class="text-center text-muted mb-5">Please provide some information on how we can help you and we'll follow up.</h6>

                    <form @submit.prevent="send">
                      <div class="form-group">
                        <label>Work Email <span class="text-danger">*</span></label>
                        <input type="email" class="form-control" v-model="form.email" required />
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-6">
                          <label>First Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" v-model="form.firstname" required/>
                        </div>
                        <div class="form-group col-sm-6">
                          <label>Last Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" v-model="form.lastname" required/>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Company Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" v-model="form.company" required/>
                      </div>
                      <div class="form-group">
                        <label>Job Title</label>
                        <input type="text" class="form-control" v-model="form.title" />
                      </div>
                      <div class="form-group">
                        <label>Additional Information <span class="text-danger">*</span></label>
                        <Textarea v-model="form.message" rows="8" required />
                      </div>
                      <div class="form-group pt-3 mx-sm-3 px-sm-5 mb-4">
                        <button type="submit" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" :disabled="waiting"><i v-if="waiting" class="fal fa-spinner fa-spin mr-2" style="margin-left: -22px;"></i>Send Message</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>

              <AlertCard v-else icon="fa-envelope-open-text" title="Thank you for getting in touch" message="We appreciate you contacting us. Someone from our team will get back to you shortly." />

            </div>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AlertCard from '@/components/AlertCard.vue';
import Textarea from '@/components/Textarea.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      form: {
        email: '',
        firstname: '',
        lastname: '',
        company: '',
        title: '',
        message: '',
      },
      sentMessage: false,
      waiting: false,
      previousPath: '/',
    };
  },
  methods: {
    send() {
      this.waiting = true;
      this.$api.post('/contact', this.form)
        .then(() => {
          this.waiting = false;
          this.sentMessage = true;
        })
        .catch((e) => {
          this.waiting = false;
          toast.danger(this, e.response.data.message);
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPath = from.path; // eslint-disable-line no-param-reassign
    });
  },
  components: {
    Header,
    AlertCard,
    Textarea,
  },
};
</script>
